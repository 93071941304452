import React, { useEffect, useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap';
import { useFormik } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';
import CropImage from '../../../ImageCrop/CropImage';
import { Tooltip } from 'react-tooltip'

function ChangeModal({ datas, show, setChangeShow, render, setRender }) {

    let [fileState, setFileState] = useState(datas.image)
    let [file, setFile] = useState(null)

    let [editorData, setEditorData] = useState(datas.content)

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    const [pending, setPending] = useState(false);

    const handleImageUpload = async (file, url) => {
        try {



            const fileType = getFileTypeFromExtension(file.name.split('.').pop());

            if (fileType === "image") {
                let text = $(".type_file.file1 p").text();
                if (file) $(".type_file.file1 p").text(file.name)
                else $(".type_file.file1 p").text(text)

                let url = await convertFileToBase64(file)
                setFileState(url)
                setFile(file)
            }
            else {
                Swal.fire(
                    'Unsuccessful',
                    `Invalid file selected !`,
                    'error'
                )
            }


        } catch (error) {
            console.log(error)
        }
    };


    function getFileTypeFromExtension(extension) {
        switch (extension.toLowerCase()) {
            case 'jpg':
                return 'image';
            case 'jpeg':
                return 'image';
            case 'png':
                return 'image';
            case 'webp':
                return 'image';
            case 'pdf':
                return 'pdf';
            case 'doc':
                return 'doc';
            case 'docx':
                return 'docx';
            default:
                return 'undefined';
        }
    }

    const { values, handleChange, errors, handleSubmit, initialValues } = useFormik({
        initialValues: {
            product_name: `${datas.product_name}`,
            link: `${datas.link}`,
            image: `${fileState}`,
            videoLink: `${datas.videoLink}`,
            content: `${datas.content}`,
            capacity: `${datas.capacity}`,
            overall_size: `${datas.overall_size}`,
            power_supply: `${datas.power_supply}`,
            air_pressure: `${datas.air_pressure}`,
            shot_diameter: `${datas.shot_diameter}`,
            production_capacity: `${datas.production_capacity}`,
            id: `${datas.id}`
        },
        onSubmit: (values) => {
            values.content = editorData;

            let changeCharacters = {
                "ç": "c",
                "ğ": "g",
                "ş": "s",
                "ü": "u",
                "ı": "i",
                "ö": "o",
                "Ğ": "G",
                "Ş": "S",
                "Ü": "U",
                "İ": "I",
                "Ö": "O",
                "á": "a",
                "é": "e",
                "í": "i",
                "ó": "o",
                "ú": "u",
                "Á": "A",
                "É": "E",
                "Í": "I",
                "Ó": "O",
                "Ú": "U",
            }

            let formattedText = values.product_name.toLowerCase();

            for (const [anahtar, değer] of Object.entries(changeCharacters)) {
                formattedText = formattedText.replace(new RegExp(anahtar, 'g'), değer);
            }

            values.link = formattedText.replace(/ /g, '-');
            values.image = fileState;

            const formData = new FormData();
            formData.append('file', file);

            setPending(true)
            axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({ data }) => {


                // values.image = `https://154.53.166.52:1000/images/${data.filename}`;

                axios.post("/api/replaceProduct", values)
                    .then(({ data }) => {
                        if (data.err) {
                            Swal.fire(
                                'Error!',
                                'Something went wrong. Please Check the Console Screen!',
                                'error'
                            )
                            console.log(data.err)
                        }
                        else {
                            Swal.fire(
                                'Successful!',
                                'Product Updated Successfully!',
                                'success'
                            ).then((result) => {
                                if (result.isConfirmed) {
                                    setChangeShow(false);
                                }
                            })
                            setRender(!render)
                        }

                        setPending(false)
                    }
                    )
                    .catch(err => {
                        Swal.fire(
                            'Error!',
                            'Something went wrong. Please Check the Console Screen!',
                            'error'
                        )
                        console.log(err)
                        setPending(false)
                    });

            }).catch(error => {
                console.error(error);
            });


        },
    });

    return (
        <>
            <Tooltip anchorSelect=".space-tooltip" place="top">
                To enter multiple values, you can put a vertical line (|) between them.
            </Tooltip>
            <Modal className='change_product' show={show}>
                {
                    pending ? <div className='penging'>
                        <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem", fontSize: "1.5rem" }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> : null
                }
                <Modal.Header closeButton onHide={setChangeShow}>
                    <Modal.Title>Change Product Informations</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className='form_flex'>
                            <div className=''>
                                <label htmlFor="product_name">Product Name</label>
                                <input type="text" defaultValue={initialValues.product_name} name='product_name' onChange={handleChange} />
                            </div>
                            <div className=''>
                                <label htmlFor="content">Content</label>
                                <CKEditor
                                    name='content'
                                    data={initialValues.content}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setEditorData(data);
                                    }}
                                    editor={ClassicEditor}

                                />
                            </div>
                            <div>
                                <label htmlFor="image">Image <small>(1920x1080)</small></label>
                                <div className='type_file file1'>
                                    <input type="file" className="file" name='image' onChange={(e) => handleImageUpload(e.target.files[0])} />
                                    <div className='type_file_prev'>
                                        {fileState ? <img src={fileState} alt="" /> : <img src="https://cdn.iconscout.com/icon/free/png-256/free-photo-size-select-actual-1782180-1512958.png" alt="" />}
                                        <p>
                                            Select or drag a file to this area to replace the image
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label htmlFor="videoLink">Youtube Video <small>(Iframe Code)</small></label>
                                <input type="text" defaultValue={initialValues.videoLink} name='videoLink' onChange={handleChange} />
                            </div>

                            <div className='three_flex'>
                                <div>
                                    <label htmlFor="capacity">Capacity</label>
                                    <input type="text" defaultValue={initialValues.capacity} name='capacity' onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="overall_size">Overall Size</label>
                                    <input type="text" defaultValue={initialValues.overall_size} name='overall_size' onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="power_supply">Power Supply</label>
                                    <input type="text" defaultValue={initialValues.power_supply} name='power_supply' onChange={handleChange} />
                                </div>
                            </div>
                            <div className='three_flex'>
                                <div>
                                    <label htmlFor="air_pressure">Air Pressure</label>
                                    <input type="text" defaultValue={initialValues.air_pressure} name='air_pressure' onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="shot_diameter">Shot Diameter <span className='space-tooltip tooltip_icon'>?</span></label>
                                    <input type="text" defaultValue={initialValues.shot_diameter} name='shot_diameter' onChange={handleChange} />
                                </div>
                                <div>
                                    <label htmlFor="production_capacity">Production Capacity <span className='space-tooltip tooltip_icon'>?</span></label>
                                    <input type="text" defaultValue={initialValues.production_capacity} name='production_capacity' onChange={handleChange} />
                                </div>
                            </div>
                            <div className=''>
                                <div>
                                    <button type='submit'>Save</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={setChangeShow}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={setChangeShow}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>

        </>
    )
}

export default ChangeModal
