import React, { useEffect, useState } from "react";
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';

import Main from './Components/Pages/Index/Index';
import About from './Components/Pages/About/Index';
import Products from './Components/Pages/Products/Index';
import Contact from './Components/Pages/Contact/Index';
// import PanelRouter from './Components/Panel/PanelRouter';
import { AnimatePresence } from 'framer-motion';

import PageNavigation from './Components/PageNavigation';
import NotFound from "./Components/Pages/NotFound";
import Admin from "./Components/Panel/Pages/Admin";
import ProductsManagement from "./Components/Panel/Pages/Products/Index";
import axios from "axios";
import { UserProvider } from "./Components/Context/UserContext";
import { Redirect } from "./Components/Redirect";

function getCookie(name) {
  const cookieString = decodeURIComponent(document.cookie);
  const cookies = cookieString.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return cookie.substring(name.length + 1);
    }
  }

  return null;
}

function App() {
  const location = useLocation();
  const [login, setLogin] = useState(false);

  const [loadFile, setLoadFile] = useState(false);


  const token = `${getCookie('token')}`;
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['Content-Type'] = "application/json";
  axios.defaults.baseURL = "https://api.falconsavunma.com";
  // http://localhost:5000
  axios.defaults.headers.common['authorization'] = `${getCookie('token')}`;
  axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
    if (error.response && error.response.status === 401) {
      document.location.reload();
    }

    return Promise.reject(error);
  });

  // useEffect(() => {
  //   console.clear();
  // }, []);


  return (
    <>
      <UserProvider>
        <Routes location={location} key={location.pathname}>

          <Route index element={<Main />} />
          <Route path='/about-us' element={<PageNavigation title={"About Us - Falcon Savunma"} page={<About />} />} />
          <Route path='/product/:link' element={<PageNavigation title={"Product - Falcon Savunma"} page={<Products />} />} />
          <Route path='/contact-us' element={<PageNavigation title={"Contact Us - Falcon Savunma"} page={<Contact />} />} />
          <Route path="/admin" element={<Admin setLogin={setLogin} login={login} />}>
            <Route index element={<ProductsManagement />} />
          </Route>

          <Route path='*' element={<Redirect url="/" />} />
        </Routes>
      </UserProvider>


    </>

  );
}

export default App;
