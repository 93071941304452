import React, { useEffect, useState } from 'react'
import transition from '../../../transition'
import Iframe from 'react-iframe'
import { useFormik } from 'formik';
import { ContactValidation } from '../../Validation';
import emailjs from 'emailjs-com';
import swal from 'sweetalert';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PageLeftArea from '../../PageComponents/PageLeftArea';
import PageRightArea from '../../PageComponents/PageRightArea';

function Index() {


    const [iframeLoad, setIframeLoad] = useState(false);

    useEffect(() => {
        setIframeLoad(!iframeLoad)
    }, []);

    const { handleSubmit, handleChange, errors, touched } = useFormik({
        initialValues: {
            form_name: "İletişim Formu",
            fullname: "",
            email: "",
            phoneNumber: "",
            message: ""
        },

        validationSchema: ContactValidation,

        onSubmit: async (values, { resetForm }) => {

            emailjs.send('service_0az28zn', 'template_njsvu8h', values, 's3p5XiPCnHaIMnJf9')
                .then((response) => {
                    // console.log('Mail Gönderildi!', response.status, response.text);

                    axios.post('/api/setFormData', values).then(({ data }) => {
                        // console.log(data)
                    })


                    swal({
                        title: "Success",
                        text: "Email was sent successfully. We will get back to you as soon as possible.",
                        icon: "success",
                    })
                    document.querySelector("#contact-form").reset();
                    resetForm();
                }, (err) => {
                    swal({
                        title: "Error",
                        text: "Email was sent successfully. We will get back to you as soon as possible.",
                        icon: "error",
                    })
                    console.log(err);
                });
        },
    });

    return (
        <>
            <main>
                <section className="page_banner_main">
                    <div className="container">
                        <div className="page_banner">
                            <h1>
                                Contact us
                            </h1>
                        </div>
                    </div>
                </section>


                <div className='_Page_Content_Area'>
                    <PageLeftArea />
                    <section className='full_area'>
                        <div className="container">
                            <div className="page_content contact_page">
                                {iframeLoad ? <>
                                    <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12226.519314785122!2d32.748752!3d39.994377!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34a26fdc0d989%3A0x38f103324e03b0f6!2sSerhat%2C%201480.%20Sk.%20No%3A50%2C%2006374%20%C4%B0vedik%20Osb%2FYenimahalle%2FAnkara!5e0!3m2!1str!2str!4v1710911886783!5m2!1str!2str"
                                        width="100%"
                                        height="500px"
                                        styles={{ filter: "invert(90%) grayscale(.5)" }} /></> : <SkeletonTheme baseColor="#303030" highlightColor="#444">
                                    <div className='row g-3 mt-2'>
                                        <div className='col-12'>
                                            <Skeleton borderRadius={0} height={'500px'} count={1} />
                                        </div>
                                    </div>
                                </SkeletonTheme>}


                                <div>
                                    <h2 className='form_title'>
                                        Contact us
                                    </h2>
                                    <form id='contact-form' onSubmit={handleSubmit}>
                                        <div className='form-flex'>
                                            <div>
                                                <input type="text" name='fullname' onChange={handleChange} placeholder='Fullname' />
                                                {errors.fullname && touched.fullname ? (
                                                    <span className='validation_error_span'>{errors.fullname}</span>
                                                ) : null}
                                            </div>
                                            <div>
                                                <input type="text" name='email' onChange={handleChange} placeholder='E-mail' />
                                                {errors.email && touched.email ? (
                                                    <span className='validation_error_span'>{errors.email}</span>
                                                ) : null}
                                            </div>
                                            <div>
                                                <input type="text" name='phoneNumber' onChange={handleChange} placeholder='Phone' />
                                                {errors.phoneNumber && touched.phoneNumber ? (
                                                    <span className='validation_error_span'>{errors.phoneNumber}</span>
                                                ) : null}
                                            </div>
                                            <div className='f-w'>
                                                <textarea name='message' onChange={handleChange} placeholder='Message' />
                                                {errors.message && touched.message ? (
                                                    <span className='validation_error_span'>{errors.message}</span>
                                                ) : null}
                                            </div>
                                            <div className='f-w'>
                                                <button><span><i className="fa-regular fa-paper-plane"></i></span> <span>Send</span></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <PageRightArea />
                </div>
            </main >


        </>
    )
}

export default Index
