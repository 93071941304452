import React, { useEffect, useState } from 'react'
import transition from '../../../transition'
import { Link, useNavigate, useParams } from 'react-router-dom';
import "video-react/dist/video-react.css";
import axios from 'axios';
import 'swiper/css';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { Swiper, SwiperSlide } from 'swiper/react';
import PageLeftArea from '../../PageComponents/PageLeftArea';
import PageRightArea from '../../PageComponents/PageRightArea';
import $ from 'jquery';

function Index() {
    const params = useParams();
    const navigation = useNavigate();

    const [currentData, setCurrentData] = useState([]);
    const [currentImages, setCurrentImages] = useState(null);
    const [otherData, setOtherData] = useState([]);

    useEffect(() => {
        // document.title = `${params.link} - Falcon Savunma`;
        window.scrollTo(0, 0);
        axios.get(`/getProducts`)
            .then(({ data }) => {
                let currentProduct = data.filter((item) => item.link === params.link)[0];

                if (currentProduct && currentProduct.length !== 0) {
                    // console.log(currentProduct)
                    let otherProduct = data.filter((item) => item.link !== params.link)
                    axios.get(`/getPhotosOfProducts`)
                        .then(({ data }) => {
                            let currentImages = data.filter((item) => item.product_id === currentProduct.id)
                            setCurrentImages(currentImages)
                        })
                        .catch((error) => console.log(error))

                    setCurrentData(currentProduct);
                    setOtherData(otherProduct)
                }
                else {
                    window.history.back()
                }

            })
            .catch((error) => console.log(error))



    }, [params]);

    return (
        <>
            <main>
                <section className="page_banner_main">
                    <div className="container">
                        <div className="page_banner">
                            <h1>
                                {currentData && currentData.product_name ? currentData.product_name : <SkeletonTheme baseColor="#303030" highlightColor="#444">
                                    <Skeleton borderRadius={0} height={'6rem'} width={'50%'} count={1} />
                                </SkeletonTheme>}
                            </h1>
                        </div>
                    </div>
                </section>


                <div className='_Page_Content_Area'>
                    <PageLeftArea />
                    <section className='full_area'>
                        {/*  */}

                        <div className="container">
                            <div className="page_content">

                                {currentData && currentData.product_name ? <>
                                    {currentImages && currentImages.length === 0 ? <>
                                        <div className='row g-3 pb-3'>
                                            <div className='col-12'>
                                                <img src={`${currentData.image}`} alt={currentData.product_name} />
                                            </div>
                                        </div>
                                    </> : currentImages && currentImages.length > 0 ? <>
                                        <div className='row g-3 pb-3'>
                                            {currentImages.map((data, key) => <div key={key} className='col-12'>
                                                <img src={`${data.image}`} alt={`${currentData.product_name}-${key}`} />
                                            </div>)}

                                        </div>
                                    </> : <SkeletonTheme baseColor="#303030" highlightColor="#444">
                                        <div className='row g-3 pb-3'>
                                            <div className='col-12'>
                                                <Skeleton borderRadius={0} height={'35rem'} count={1} />
                                            </div>
                                        </div>
                                    </SkeletonTheme>}


                                    <div dangerouslySetInnerHTML={{ __html: currentData.content }} />

                                    {/* <div className='video' style={{ width: "75%", margin: "auto" }}>
                <Player
                    playsInline
                    src="https://storage.acerapps.io/app-1176/Digimondi/video/banner-bg2.mp4"
                >
                    <BigPlayButton position="center" />
                </Player>
            </div> */}

                                    {currentData.capacity ||
                                        currentData.overall_size ||
                                        currentData.power_supply ||
                                        currentData.air_pressure ||
                                        currentData.shot_diameter ||
                                        currentData.production_capacity ? <div className='product_attrs'>
                                        {/* <h2>
                        Attributes
                    </h2> */}
                                        <ul>
                                            {currentData.capacity && currentData.capacity !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Capacity</p>
                                                    </div>
                                                    <div>
                                                        {currentData.capacity}
                                                    </div>
                                                </li>
                                                : null}

                                            {currentData.overall_size && currentData.overall_size !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Overall Size (LWH)</p>
                                                    </div>
                                                    <div>
                                                        {currentData.overall_size}
                                                    </div>
                                                </li>
                                                : null}

                                            {currentData.power_supply && currentData.power_supply !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Power Supply</p>
                                                    </div>
                                                    <div>
                                                        {currentData.power_supply}
                                                    </div>
                                                </li>
                                                : null}

                                            {currentData.air_pressure && currentData.air_pressure !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Air Pressure</p>
                                                    </div>
                                                    <div>
                                                        {currentData.air_pressure}
                                                    </div>
                                                </li>
                                                : null}

                                            {currentData.shot_diameter && currentData.shot_diameter !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Shot Diameter (mm)</p>
                                                    </div>
                                                    <div>
                                                        {currentData.shot_diameter.split('|').length > 1 ? currentData.shot_diameter.split('|').map((data, key) => {
                                                            return <span key={key}>
                                                                {data}
                                                                {key !== currentData.shot_diameter.split('|').length - 1 ? <br /> : null}
                                                            </span>
                                                        }) : null}
                                                    </div>
                                                </li>
                                                : null}

                                            {currentData.production_capacity && currentData.production_capacity !== "" ?
                                                <li>
                                                    <div className='head_attr'>
                                                        <p>Production Capacity (kg/h)</p>
                                                    </div>
                                                    <div>
                                                        {currentData.production_capacity.split('|').length > 1 ? currentData.production_capacity.split('|').map((data, key) => {
                                                            return <span key={key}>
                                                                {data}
                                                                {key !== currentData.production_capacity.split('|').length - 1 ? <br /> : null}
                                                            </span>
                                                        }) : null}
                                                    </div>
                                                </li>
                                                : null}
                                        </ul>
                                    </div> : null}

                                    {currentData.videoLink ? <div className='iframe_area' dangerouslySetInnerHTML={{ __html: currentData.videoLink }} /> : null}

                                    {String(currentData.is_table) === "1" ? <>
                                        <div className='pro_table_flex'>
                                            <div>
                                                <p>Diemeter</p>
                                                <p>inches</p>
                                                <p>mm</p>
                                            </div>
                                            <div>
                                                <div>
                                                    <ul className='first_list'>
                                                        <li>
                                                            9
                                                        </li>
                                                        <li>
                                                            8.5
                                                        </li>
                                                        <li>
                                                            8
                                                        </li>
                                                        <li>
                                                            7.5
                                                        </li>
                                                        <li>
                                                            7
                                                        </li>
                                                        <li>
                                                            6
                                                        </li>
                                                        <li>
                                                            5
                                                        </li>
                                                        <li>
                                                            4
                                                        </li>
                                                        <li>
                                                            3
                                                        </li>
                                                        <li>
                                                            2
                                                        </li>
                                                        <li>
                                                            1
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr />
                                                <div>
                                                    <ul>
                                                        <li>
                                                            .08
                                                        </li>
                                                        <li>
                                                            .085
                                                        </li>
                                                        <li>
                                                            .09
                                                        </li>
                                                        <li>
                                                            .095
                                                        </li>
                                                        <li>
                                                            .10
                                                        </li>
                                                        <li>
                                                            .11
                                                        </li>
                                                        <li>
                                                            .12
                                                        </li>
                                                        <li>
                                                            .13
                                                        </li>
                                                        <li>
                                                            .14
                                                        </li>
                                                        <li>
                                                            .15
                                                        </li>
                                                        <li>
                                                            .16
                                                        </li>
                                                    </ul>
                                                </div>
                                                <hr />
                                                <div>
                                                    <ul>
                                                        <li>
                                                            2.03
                                                        </li>
                                                        <li>
                                                            2.16
                                                        </li>
                                                        <li>
                                                            2.29
                                                        </li>
                                                        <li>
                                                            2.41
                                                        </li>
                                                        <li>
                                                            2.54
                                                        </li>
                                                        <li>
                                                            2.79
                                                        </li>
                                                        <li>
                                                            3.05
                                                        </li>
                                                        <li>
                                                            3.30
                                                        </li>
                                                        <li>
                                                            3.56
                                                        </li>
                                                        <li>
                                                            3.81
                                                        </li>
                                                        <li>
                                                            4.05
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </> : null}

                                    <h2 className='other_title'>
                                        Other Products
                                    </h2>
                                    <Swiper
                                        spaceBetween={25}
                                        slidesPerView={1}
                                        breakpoints={{
                                            768: {
                                                slidesPerView: 2,
                                            },
                                        }}
                                    >
                                        {otherData ? otherData.map((data, key) =>
                                            <SwiperSlide key={key} className='product_slide_item'>
                                                <Link to={`/product/${data.link}`}>
                                                    <img src={data.image ? data.image : ""} alt={data.product_name} />
                                                    <p>
                                                        {data.product_name}
                                                    </p>
                                                </Link>
                                            </SwiperSlide>
                                        ) : null}
                                    </Swiper>
                                </> : <SkeletonTheme baseColor="#303030" highlightColor="#444">
                                    <div className='row g-3 pb-3'>
                                        <div className='col-12'>
                                            <Skeleton borderRadius={0} height={'35rem'} count={1} />
                                        </div>
                                    </div>
                                    <p>
                                        <Skeleton borderRadius={0} count={7} />
                                    </p>
                                    <p>
                                        <Skeleton borderRadius={0} count={7} />
                                    </p>
                                    <div className='row g-3 pb-3'>
                                        <div className='col-6'>
                                            <Skeleton borderRadius={0} height={'15rem'} count={1} />
                                        </div>
                                        <div className='col-6'>
                                            <Skeleton borderRadius={0} height={'15rem'} count={1} />
                                        </div>
                                    </div>

                                </SkeletonTheme>}
                            </div>
                        </div>
                    </section>
                    <PageRightArea />
                </div>
            </main >

        </>
    )
}

export default Index

