import React, { useEffect, useState } from 'react'
import transition from '../../../transition'
import axios from 'axios'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import PageLeftArea from '../../PageComponents/PageLeftArea'
import PageRightArea from '../../PageComponents/PageRightArea'

function Index() {

    const [content, setContent] = useState("");

    useEffect(() => {
        axios.get('/getAbout').then(({ data }) => {
            setContent(data)
        })
            .catch((err) => console.log(err))
    }, []);

    return (
        <>
            <main>
                <section className="page_banner_main">
                    <div className="container">
                        <div className="page_banner">
                            <h1>
                                Falcon <br /> Savunma
                            </h1>
                        </div>
                    </div>
                </section>


                <div className='_Page_Content_Area'>
                    <PageLeftArea />
                    <section className='full_area'>
                        <div className="container">
                            <div className="page_content">
                                {content !== "" ? <>
                                    <div dangerouslySetInnerHTML={{ __html: content.data }} />

                                    <div className="row g-3 mt-2">
                                        {content.images.map((data, key) => {
                                            if (data && data !== "") {
                                                return <div className={key === 0 ? "col-12" : "col-6"} key={key}>
                                                    <img src={`${data}`} alt="" />
                                                </div>
                                            }
                                            else {
                                                return null
                                            }
                                        })}
                                    </div>
                                </> : <>
                                    <SkeletonTheme baseColor="#303030" highlightColor="#444">
                                        <p>
                                            <Skeleton borderRadius={0} count={7} />
                                        </p>
                                        <p>
                                            <Skeleton borderRadius={0} count={7} />
                                        </p>
                                        <div className='row g-3 mt-2'>
                                            <div className='col-12'>
                                                <Skeleton borderRadius={0} height={'25rem'} count={1} />
                                            </div>
                                            <div className='col-6'>
                                                <Skeleton borderRadius={0} height={'15rem'} count={1} />
                                            </div>
                                            <div className='col-6'>
                                                <Skeleton borderRadius={0} height={'15rem'} count={1} />
                                            </div>
                                        </div>
                                    </SkeletonTheme>
                                </>}
                            </div>
                        </div>
                    </section>
                    <PageRightArea />
                </div>
            </main >


        </>
    )
}

export default Index