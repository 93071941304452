import React, { useEffect, useState } from 'react'
import transition from '../../../transition'
import { MainPageHeader } from '../../Layout/Header';
import BannerProduct from '../../PageComponents/BannerProduct';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

function Index() {

    const [allProduct, setAllProduct] = useState([]);
    const [pending, setPending] = useState(true);

    useEffect(() => {

        document.title = "Home - Falcon Savunma";

        // console.log("Uygulama başladı - İstek atılıyor")

        axios.get('/getProducts').then(({ data }) => {
            // console.log("İstekten yanıt geldi")
            setAllProduct(data)
            setPending(false)
        }).catch((err) => console.log(err))

        // console.log("İstek sonrası log")

        return () => {
            // document.querySelector("#vendorScript").remove();
            document.querySelector("#mainCssLink").remove();
            document.querySelector("#firstScript").remove();
            document.location.reload();
        }
    }, []);

    useEffect(() => {




        if (pending === false) {

            if (document.querySelector("#vendorScript") == null) {
                var vendorScript = document.createElement('script');
                vendorScript.id = "vendorScript";
                vendorScript.src = 'https://storage.acerapps.io/app-1176/falcon/vendor.bundle2.js';
                // vendorScript.defer = true;
                document.body.appendChild(vendorScript);
            }

            var mainCssLink = document.createElement('link');
            mainCssLink.id = "mainCssLink";
            mainCssLink.href = 'https://storage.acerapps.io/app-1176/falcon/MainPage2.1.css';
            mainCssLink.rel = 'stylesheet';
            document.head.appendChild(mainCssLink);

            var firstScript = document.createElement('script');
            firstScript.id = "firstScript";
            firstScript.src = 'https://storage.acerapps.io/app-1176/falcon/first4.js';
            // firstScript.defer = true;
            document.body.appendChild(firstScript);
        }
    }, [pending]);


    return (
        <>
            {pending === false ? <>

                <MainPageHeader />
                <div className='all_content'>
                    <header className="l-header js-header b-fonts__medium"></header>
                    <div className="l-navSp b-fonts__medium js-nav"> </div>
                    <main id="page" className="js-smooth-scroll-wrapper">
                        <div id="contents" className="js-smooth-scroll-content">
                            <div id="top" className="page-content"></div>

                        </div>
                    </main>
                    <aside id="side-contact" className="p-sideContact">
                        {/* <address className="l-wide-container">
                        <dl className="p-sideContact__inner js-target--sideFix">
                            <div className="p-sideContact__unit">
                                <dd><a className="js-target--pointer__stateChange lang-fonts--en c-link--underline js-hover"
                                    data-hover-in-time="1010" data-hover-out-time="850"
                                    href="mailto: &#105;&#110;&#102;&#111;&#64;&#97;&#115;&#109;&#111;&#98;&#105;&#117;&#115;&#46;&#99;&#111;&#46;&#106;&#112;"><span
                                        className="js-target__inner--pointer__stateChange c-link--underline__inner">&#105;&#110;&#102;&#111;&#64;&#97;&#115;&#109;&#111;&#98;&#105;&#117;&#115;&#46;&#99;&#111;&#46;&#106;&#112;<span
                                            className="c-link--underline__line"></span></span></a></dd>
                            </div>
                            <div className="p-sideContact__unit">
                                <dd><a className="js-target--pointer__stateChange lang-fonts--en c-link--underline js-hover"
                                    data-hover-in-time="1010" data-hover-out-time="850"
                                    href="mailto: &#106;&#111;&#98;&#115;&#64;&#97;&#115;&#109;&#111;&#98;&#105;&#117;&#115;&#46;&#99;&#111;&#46;&#106;&#112;"><span
                                        className="js-target__inner--pointer__stateChange c-link--underline__inner">&#106;&#111;&#98;&#115;&#64;&#97;&#115;&#109;&#111;&#98;&#105;&#117;&#115;&#46;&#99;&#111;&#46;&#106;&#112;<span
                                            className="c-link--underline__line"></span></span></a></dd>
                            </div>
                        </dl>
                    </address> */}
                    </aside>
                    <div id="stage" className="p-stage">
                        <div id="app-canvas"></div>
                        <ul className="p-stage__menu">
                            {allProduct ? allProduct.map((data, key) => <BannerProduct
                                key={key}
                                data={data}
                            />) : null}
                        </ul>
                    </div>
                    {/* <div id="indicator">
                        <div id="app-indicator"></div>
                    </div> */}
                    {/* <div id="pageCover" className="p-pageCover js-target--height__screenFix--mobile" data-fix-height-ratio="1.1">
                        <div className="p-pageCover__inner js-target--height__screenFix--mobile" data-fix-height-ratio="1">
                            <p id="pageCoverText" className="p-pageCover__text b-fonts__medium">
                                NEXT
                            </p>
                        </div>
                    </div> */}

                    {/* <div id="app-mousePointer"></div> */}
                    <div className='first_load'>
                        <img src="https://storage.acerapps.io/app-1517/assets/img/logo.png" alt="Falcon Savunma" />
                        <div className="spinner-border text-light" style={{ width: "7rem", height: "7rem", fontSize: "2.5rem" }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div id="loading" className="p-loading">
                        <div className="p-loading__outer">
                            <div className="p-loading__bg"></div>
                            <div className="p-loading__inner">
                                <div>
                                    <div className="p-loading__front">
                                        <div className="p-loading__front__inner">

                                        </div>
                                    </div>
                                    <div className="p-loading__back">
                                        <div className="p-loading__back__inner">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </> : <div className='all-content'>
                <div className='first_load'>
                    <img src="https://storage.acerapps.io/app-1517/assets/img/logo.png" alt="Falcon Savunma" />
                    <div className="spinner-border text-light" style={{ width: "7rem", height: "7rem", fontSize: "2.5rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>}

        </>
    )
}

export default Index