import React, { useEffect } from 'react'
import { Header } from './Layout/Header'
import Footer from './Layout/Footer';
import PageRightArea from './PageComponents/PageRightArea';
import PageLeftArea from './PageComponents/PageLeftArea';

function PageNavigation({ page, bannerText, title }) {

    useEffect(() => {
        document.body.classList = "is-ready";
        document.documentElement.classList = "";
        document.querySelector("#wrapper").style = "";

        document.title = title;

    }, [title]);

    return (
        <>
            <Header />

            {page}

            <Footer />
        </>
    )
}

export default PageNavigation
