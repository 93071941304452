import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ContentModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='contentModal'
        >
            <Modal.Body>
                {props.title ? <h4>{props.title}</h4> : null}
                <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide} className='btn_style' style={{ width: "max-content", height: "3rem", marginRight: "0" }}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}