import React, { useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { UserContext, UserProvider } from '../../Context/UserContext'
import axios from 'axios';
import Cookies from 'js-cookie';
import Login from './Auth/Login';
import Inclusive from '../Layout/Inclusive'
import '../Css/panel.css'

function getCookie(name) {
    // Çerezler boşluğa göre ayrılır, her çerez "key=value" şeklinde bir stringdir.
    // Çerezler arasında dolaşarak istediğimiz çerezi bulup döndürebiliriz.
    const cookieString = decodeURIComponent(document.cookie);
    const cookies = cookieString.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim(); // Boşlukları kaldırıyoruz.
        // Eğer çerez adı verilen isme eşitse, bu çerezi döndürüyoruz.
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1); // İsimden sonraki değeri alıyoruz.
        }
    }


    // Eğer çerez bulunamazsa, null döndürüyoruz.
    return null;
}


function Admin({ login, setLogin }) {


    // const [login, setLogin] = useState(false);
    // const { user, setUser } = useContext(UserContext);

    // const token = `${getCookie('token')}`;
    // axios.defaults.withCredentials = true;
    // axios.defaults.headers.common['Content-Type'] = "application/json";
    // axios.defaults.baseURL = "http://localhost:5000/";

    // axios.defaults.headers.common['authorization'] = `${getCookie('token')}`;
    // axios.interceptors.response.use((response) => {
    //     return response;
    // }, (error) => {
    //     if (error.response && error.response.status === 401) {
    //         document.location.reload();
    //     }

    //     return Promise.reject(error);
    // });




    const [activePage, setActivePage] = useState(null);


    const { user, setUser } = useContext(UserContext);


    useEffect(() => {

        if (sessionStorage.getItem("page")) {
            setActivePage(sessionStorage.getItem("page"))
        } else {
            setActivePage("/products")
        }

        axios.get('/user').then((response) => {
            if (response.data.loggedIn === true) {
                try {
                    setUser({ email: response.data.user.email, username: response.data.user.username, password: response.data.user.password })
                    setLogin(response.data.loggedIn)
                } catch (error) {
                    console.log(error)
                }

            } else {
                setLogin(false)
            }

        }).catch((err) => {
            console.log(err)
        })
        // console.log(Cookies.get('token'))
    }, []);

    return (
        <>
            {
                Cookies.get('token') ?
                    <>
                        <Inclusive activePage={activePage}
                            setActivePage={setActivePage} />
                    </>
                    : <Login login={login} setLogin={setLogin} />
            }
        </>
    )
}

export default Admin
