import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function ContentModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='contentModal'
        >
            <Modal.Header>
                <h5>
                    {props.content.name} <br />
                    <small>{props.content.email}</small>
                </h5>
            </Modal.Header>
            <Modal.Body>
                <div dangerouslySetInnerHTML={{ __html: props.content.content }} />
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide} className='btn_style' style={{ width: "max-content", height: "3rem", marginRight: "0" }}>Close</button>
            </Modal.Footer>
        </Modal>
    );
}