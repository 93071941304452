import React from 'react'

function NotFound() {
    return (
        <>
            <div className='not_found'>
                <h1>The page you are looking for was not found!</h1>
                <button className='come_back btn_style' onClick={() => window.history.back()}>Return to Previous Page</button>
            </div>
        </>
    )
}

export default NotFound
