import React from 'react'
import DataTable, { createTheme } from 'react-data-table-component';

export default function Table({ columns, datas, noDataComponentText }) {

    createTheme('solarized', {
        text: {
            primary: 'white',
        },
        background: {
            default: '#131313',
        },
    }, 'dark')




    return (
        <DataTable
            columns={columns}
            data={datas}
            noDataComponent={noDataComponentText}
            pagination={true}
            paginationRowsPerPageOptions={[10, 20, 50, 100]}
            theme="solarized"
        />
    )
}


// paginationComponentOptions={{
//     rowsPerPageText: 'Sayfa başına satır sayısı:',
//     rangeSeparatorText: 'arasından',
//     noRowsPerPage: false,
// }}