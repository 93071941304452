import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import SocialMedias from '../PageComponents/SocialMedias'
import axios from 'axios';

function Footer() {

    const [settings, setSettings] = useState({});

    useEffect(() => {
        axios.get('/getSettings').then(({ data }) => {
            setSettings(data)
        })
    }, []);


    return (
        <>
            <footer>
                <img src="https://storage.acerapps.io/app-1517/assets/img/logo.png" className='footer_logo' alt="" />

                <hr />

                <div className='container'>
                    <ul className='footer_links'>
                        <li>
                            <Link to={"/"}>
                                Home
                            </Link>
                        </li>
                        {/* <li>
                            <Link to={"/products"}>
                                Products
                            </Link>
                        </li> */}
                        <li>
                            <Link to={"/about-us"}>
                                About Us
                            </Link>
                        </li>
                        <li>
                            <Link to={"/contact-us"}>
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>

                <hr />

                <div className='container'>
                    <ul className='footer_contact'>
                        {settings.phone_number ? <li>
                            <p>
                                Phone
                            </p>
                            <div>
                                {settings.phone_number.split('|').length > 1 ? settings.phone_number.split('|').map((data, key) => {
                                    return <div style={{ display: 'inline' }} key={key}>
                                        <Link to={`tel:${data.replaceAll(' ', '')}`} style={{ textWrap: "nowrap" }} title={`${data}`}>{data}</Link>
                                        {key !== settings.phone_number.split('|').length - 1 ? <span>/</span> : null}
                                    </div>
                                }) : <Link to={`tel:${settings.phone_number.replaceAll(' ', '')}`} style={{ textWrap: "nowrap" }} title={`${settings.phone_number}`}>{settings.phone_number}</Link>}

                            </div>
                        </li>
                            : null}


                        {settings.mail ? <li>
                            <p>
                                Mail
                            </p>
                            <div>
                                <Link to={`mailto:${settings.mail}`} title={`${settings.mail}`}>
                                    {settings.mail}
                                </Link>
                            </div>
                        </li>
                            : null}


                        {settings.location ? <li>
                            <p>
                                Location
                            </p>
                            <div>
                                <span>
                                    {settings.location}
                                </span>
                            </div>
                        </li>
                            : null}
                    </ul>
                </div>
            </footer>
            <div className='copyright'>
                <div className='container' style={{ position: "relative" }}>
                    <p>
                        @{new Date().getFullYear()} Falcon Savunma. All rights reserved.
                    </p>
                    <SocialMedias />
                </div>
            </div>
        </>
    )
}

export default Footer
