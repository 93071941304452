import React, { useContext, useEffect, useState } from 'react'
import { Button, Row, Col } from "react-bootstrap";
import { UserContext } from '../../../Context/UserContext';
import FeatherIcons from "feather-icons-react";
import { Link, useNavigate } from 'react-router-dom';
import $ from 'jquery';
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

function Login({ setLogin }) {

    const [pending, setPending] = useState(false);
    const { setUser } = useContext(UserContext)
    const navigate = useNavigate();

    const [loader, setLoader] = useState(null);


    const { handleSubmit, handleChange, errors, touched } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: async (values) => {
            try {
                let datas = values;
                setPending(true)
                axios.post('/login', datas)
                    .then(res => {
                        setLoader(null)
                        setPending(false)
                        if (res.data.error) {
                            Swal.fire(
                                'Error',
                                `${res.data.error}`,
                                'error'
                            )
                        } else {
                            Swal.fire(
                                'Successful',
                                `Signing In`,
                                'success'
                            ).then(() => {
                                setUser({ email: res.data.user.email, username: res.data.user.username, authority: res.data.user.authority, password: res.data.user.password });
                                Cookies.set('token', res.data.token)
                                setLogin(true)
                                navigate('/admin');
                            });



                        }

                    })
                    .catch(err => {
                        setLoader(null)
                        Swal.fire(
                            'Error',
                            `Something went wrong. Please Check the Console Screen`,
                            'error'
                        )
                        console.log(err)
                    })

            } catch (error) {
                console.log(error)
            }

        },
    });

    return (
        <>

            {
                pending ? <div className='penging'>
                    <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem", fontSize: "1.5rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> : null
            }
            <div className="background">
                <div className="shape"></div>
                <div className="shape"></div>
                <div className="shape"></div>
                <div className="shape"></div>
            </div>
            <form className="form" onSubmit={handleSubmit}>
                <h3>Welcome To <br /> <span>Falcon</span></h3>

                <label htmlFor="username">Username</label>
                <input type="text" name='email' placeholder="Email" onChange={handleChange} id="username" />

                <label htmlFor="password">Password</label>
                <input type="password" name='password' placeholder="Password" onChange={handleChange} id="password" />

                <button>Log In</button>
            </form>

            {/* <form className="form" onSubmit={handleSubmit}>

                <div className="inputBox">

                    <input type="text" name='email' onChange={handleChange} required /> <i>Email</i>

                </div>
                {errors.email && touched.email ? (
                    <span className='validation_error_span'>{errors.email}</span>
                ) : null}

                <div className="inputBox">

                    <input type="password" name='password' onChange={handleChange} required /> <i>Şifre</i>

                </div>
                {errors.password && touched.password ? (
                    <span className='validation_error_span'>{errors.password}</span>
                ) : null}

                <div className="inputBox">

                    <button type='submit'>Giriş Yap</button>

                </div>

            </form> */}
        </>
    )
}

export default Login
