import React, { useContext, useEffect, useState } from 'react'
import { NavLink, Link } from 'react-router-dom';
import { UserContext } from '../../Context/UserContext';
import $ from 'jquery';
import Swal from 'sweetalert2';
import axios from "axios";
import Cookies from 'js-cookie';

function Sideheader({ activePage, setActivePage }) {

    const [openBar, setOpenBar] = useState(false);
    const [openMenuBar, setOpenMenuBar] = useState(false);
    const [openThemeBar, setOpenThemeBar] = useState(false);
    const [menus, setMenus] = useState(null);

    let [render, setRender] = useState(false);

    let { user } = useContext(UserContext)


    // Linklere tıkladıktan sonra menü barını kapatıyor.
    let barControl = () => {
        setOpenBar(true)
        setTimeout(() => {
            setOpenBar(false)
        }, 10);
    }

    // let logout = () => {

    //     const swalWithBootstrapButtons = Swal.mixin({
    //         customClass: {
    //             confirmButton: 'btn btn-success',
    //             cancelButton: 'btn btn-danger'
    //         },
    //         buttonsStyling: false
    //     })

    //     swalWithBootstrapButtons.fire({
    //         title: 'Çıkış Yapmak İstediğine Emin Misin?',
    //         // text: "Bu kategoriye ait ürünlerin menünde görünmeyecek !",
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonText: 'Çıkış Yap',
    //         cancelButtonText: 'Geri',
    //         reverseButtons: true
    //     }).then((result) => {
    //         if (result.isConfirmed) {
    //             axios.get(`/logout`)
    //                 .then(({ data }) => {
    //                     if (data.error) {
    //                         Swal.fire(
    //                             'Hata',
    //                             `Bir Hata Oluştu`,
    //                             'error'
    //                         )
    //                     }
    //                     else {
    //                         Cookies.remove('token')
    //                     }
    //                     document.location.reload();
    //                 })
    //                 .catch(err => console.log(err))
    //         }
    //     })


    // }

    return (
        <>
            <div className={openBar ? "vertical-menu open" : "vertical-menu"}>

                <div data-simplebar className="h-100">
                    <div className="navbar-brand-box side_mobile_logo">
                        <NavLink to={''} className="logo logo-dark">
                            <span className="logo-sm">
                                <img src={`${document.location.origin}/assets/images/logo.png`} width="90%" alt='Menümondi Logo' />
                            </span>
                            <span className="logo-lg">
                                <img src={`${document.location.origin}/assets/images/logo.png`} width="90%" alt='Menümondi Logo' />
                            </span>
                        </NavLink>
                    </div>
                    <div id="sidebar-menu">
                        <ul className="metismenu list-unstyled" id="side-menu">
                            <li className="menu-title">Main</li>

                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/products")]} to={'javascript:;'} className={'waves-effect'}>
                                    <i className="ti-bar-chart-alt"></i>
                                    <span>Products</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/photos")]} to={'javascript:;'} className={'waves-effect'}>
                                    <i className="ti-image"></i>
                                    <span>Photos of Products</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/text")]} to={'javascript:;'} className={'waves-effect'}>
                                    <i className="ti-book"></i>
                                    <span>About Page</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/forms")]} to={'javascript:;'} className={'waves-effect'}>
                                    <i className="ti-receipt"></i>
                                    <span>Contact Form</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/settings")]} to={'javascript:;'} className={'waves-effect'}>
                                    <i className="ti-settings"></i>
                                    <span>Settings</span>
                                </NavLink>
                            </li>



                            <li>
                                <NavLink onClick={() => [barControl, setActivePage("/")]} to={'/'} className={'waves-effect'}>
                                    <i className="ti-home"></i>
                                    <span>Back to Website</span>
                                </NavLink>
                            </li>


                            {/* <li>
                                <div onClick={logout} className={'waves-effect'}>
                                    <i className="fa-solid fa-right-from-bracket"></i>
                                    <span>Çıkış Yap</span>
                                </div>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sideheader
