import Sideheader from './Sideheader';

import Index from '../Pages/Products/Index';
import About from '../Pages/TextManagement/Index';
import Forms from '../Pages/Forms/Index';
import Settings from '../Pages/Settings/Index';
import Photos from '../Pages/PhotosOfProducts/Index';
import Tables from '../Pages/TableManagement/Index';
import { useEffect } from 'react';


function Inclusive({ activePage, setActivePage }) {


    return (
        <>
            <div className="App">
                <Sideheader activePage={activePage} setActivePage={setActivePage} />
                <div className='top_header'>
                    <button type="button" className="btn btn-sm px-3 font-size-24 header-item waves-effect" id="vertical-menu-btn">
                        <i className="mdi mdi-menu side_control"></i>
                    </button>
                </div>
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">

                            {activePage === "/products" ? <Index /> : null}
                            {activePage === "/text" ? <About /> : null}
                            {activePage === "/forms" ? <Forms /> : null}
                            {activePage === "/settings" ? <Settings /> : null}
                            {activePage === "/photos" ? <Photos /> : null}


                            {/* {activePage === "/tables" ? <Tables /> : null} */}

                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        </>

    );
}

export default Inclusive