import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import Table from '../../Components/Table';
import Swal from 'sweetalert2';
import ContentModal from '../../Components/ContentModal';
import ChangeModal from './Components/ChangeModal';
import AddModal from './Components/AddModal';

function Index() {

    const [allData, setAllData] = useState([]);
    const [pending, setPending] = useState(true);
    const [render, setRender] = useState(false);
    const [tableData, setTableData] = useState(allData);
    const [replaceInfos, setReplaceInfos] = useState(null);

    const [changeShow, setChangeShow] = useState(false);
    const [addShow, setAddShow] = useState(false);
    const [products, setProducts] = useState(false);



    let [dataId, setDataId] = useState(null)

    let replaceData = (id) => {

        setDataId(id)

        axios.get(`/getSinglePhotosOfProducts?id=${id}`)
            .then(({ data }) => {
                setReplaceInfos(data)
                setChangeShow(true)
            })
            .catch(err => console.log(err))
    }

    let removeData = (id) => {


        setDataId(id)

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are You Sure You Want to Delete??',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: "No, don't delete!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setPending(true);
                axios.post("/api/deletePhotoOfProduct", { id: id })
                    .then(({ data }) => {
                        swalWithBootstrapButtons.fire({
                            title: 'Deleted !',
                            text: "",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK',
                        })
                        setRender(!render)
                    })
                    .catch(err => console.log(err));

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: 'Deletion Canceled !',
                    text: "",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                }
                )
            }
        })
    }

    const columns = [
        {
            name: '#',
            selector: row => row.sira,
            width: "4rem"
        },
        {
            name: 'Product Name',
            selector: row => row.product_name,
            width: "18rem"
        },
        {
            name: 'Image',
            selector: row => row.image,
        },
        {
            name: 'Actions',
            selector: row => row.actions,
            width: "10rem"
        }
    ];

    const renderDataTable = (datas) => {

        if (pending) {
            return (<div className='dataLoad'>
                <p className='load_text'>Images of Products Loading</p>
                <div style={{ position: "relative" }}>
                    <div className='loader'>
                        <ClipLoader color='white' size={45} />
                    </div>
                </div>
            </div>)
        }
        return (
            <div>
                <Table columns={columns} datas={datas} noDataComponentText={"Photos Not Found"} />
            </div>
        );
    };

    useEffect(() => {
        sessionStorage.setItem("page", "/photos")
    }, []);

    useEffect(() => {
        axios.get('/getPhotosOfProducts').then((response) => {
            let datas = response.data;
            setAllData(datas)

            axios.get('/getProducts').then(({ data }) => {
                setProducts(data)
            }).catch((err) => console.log(err))

            let newTableData = [];
            datas.forEach((data, key) => {

                newTableData.push({
                    sira: `${Number(key) + 1}`, ...data, image: <a href={`${data.image}`} target='_blank' rel='noreferrer'><img className='table_image' src={`${data.image}`} alt={`${data.product_name}`} /></a>, actions: <div className='table_action'><button type="button" className="btn btn-primary" onClick={() => replaceData(data.id)}><i className="fa-solid fa-pencil"></i></button><button type="button" onClick={() => removeData(data.id)} className="btn btn-danger"><i className="fa-solid fa-trash"></i></button></div>
                })

            });

            setTableData(newTableData)
            setPending(false);

        }).catch((err) => console.log(err))
    }, [render]);

    return (
        <>
            {!pending ? <button onClick={() => setAddShow(true)} className='btn btn-success mb-3'>Add Photo in Product</button> : null}
            <div className='photos_table'>
                {renderDataTable(tableData)}
            </div>


            {changeShow ? <ChangeModal products={products} render={render} setRender={setRender} show={changeShow} setChangeShow={() => setChangeShow(false)} datas={replaceInfos} /> : null}
            {addShow ? <AddModal products={products} render={render} setRender={setRender} show={addShow} setAddShow={() => setAddShow(false)} /> : null}
        </>
    )
}

export default Index
