import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SocialMedias from './SocialMedias';
import axios from 'axios';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function PageLeftArea() {

    const [settings, setSettings] = useState("");

    useEffect(() => {
        axios.get('/getSettings').then(({ data }) => {
            setSettings(data)
        })
    }, []);

    return (
        <>
            <section className="page_left_area">{settings !== "" ?
                <ul>
                    {settings.phone_number ? <li>
                        <p>
                            Phone
                        </p>
                        <div>
                            {settings.phone_number.split('|').length > 1 ? settings.phone_number.split('|').map((data, key) => {
                                return <div style={{ display: 'inline' }} key={key}>
                                    <Link to={`tel:${data.replaceAll(' ', '')}`} style={{ textWrap: "nowrap" }} title={`${data}`}>{data}</Link>
                                    {key !== settings.phone_number.split('|').length - 1 ? <span>/</span> : null}
                                </div>
                            }) : <Link to={`tel:${settings.phone_number.replaceAll(' ', '')}`} style={{ textWrap: "nowrap" }} title={`${settings.phone_number}`}>{settings.phone_number}</Link>}

                        </div>
                    </li>
                        : null}


                    {settings.mail ? <li>
                        <p>
                            Mail
                        </p>
                        <div>
                            <Link to={`mailto:${settings.mail}`} title={`${settings.mail}`}>
                                {settings.mail}
                            </Link>
                        </div>
                    </li>
                        : null}


                    {settings.location ? <li>
                        <p>
                            Location
                        </p>
                        <div>
                            <span>
                                {settings.location}
                            </span>
                        </div>
                    </li>
                        : null}

                    <li>
                        <SocialMedias />
                    </li>
                </ul>

                : <SkeletonTheme baseColor="#303030" highlightColor="#444">
                    <ul>
                        <li>
                            <p>
                                <Skeleton borderRadius={0} width={'50%'} count={1} />
                            </p>
                            <div>
                                <Skeleton borderRadius={0} count={1} />
                            </div>
                        </li>

                        <li>
                            <p>
                                <Skeleton borderRadius={0} width={'50%'} count={1} />
                            </p>
                            <div>
                                <Skeleton borderRadius={0} count={1} />
                            </div>
                        </li>


                        <li>
                            <p>
                                <Skeleton borderRadius={0} width={'50%'} count={1} />
                            </p>
                            <div>
                                <Skeleton borderRadius={0} count={3} />
                            </div>
                        </li>


                        <li>
                            <Skeleton borderRadius={0} count={1} />
                        </li>
                    </ul>
                </SkeletonTheme>}
            </section>
        </>
    )
}

export default PageLeftArea
