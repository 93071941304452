import React, { useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import $ from 'jquery';
import axios from 'axios';
import Swal from 'sweetalert2';

function AddModal({ show, setAddShow, render, setRender, products }) {

    let [fileState, setFileState] = useState(null)

    const [file, setfile] = useState(null);
    const [pending, setPending] = useState(false);

    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    const handleImageUpload = async (file, url) => {
        try {

            if (file.size > 5 * 1024 * 1024) { // 5 MB'ın byte cinsinden karşılığı
                return Swal.fire(
                    'Unsuccessful',
                    `File size too large !`,
                    'error'
                )
            }




            const fileType = getFileTypeFromExtension(file.name.split('.').pop());

            if (fileType === "image") {
                let text = $(".type_file.file1 p").text();
                if (file) $(".type_file.file1 p").text(file.name)
                else $(".type_file.file1 p").text(text)
                setfile(file)

                let url = await convertFileToBase64(file)
                setFileState(url)
            }
            else {
                Swal.fire(
                    'Unsuccessful',
                    `Invalid file selected !`,
                    'error'
                )
            }


        } catch (error) {
            console.log(error)
        }
    };

    function getFileTypeFromExtension(extension) {
        switch (extension.toLowerCase()) {
            case 'jpg':
                return 'image';
            case 'jpeg':
                return 'image';
            case 'png':
                return 'image';
            case 'webp':
                return 'image';
            case 'pdf':
                return 'pdf';
            case 'doc':
                return 'doc';
            case 'docx':
                return 'docx';
            default:
                return 'undefined';
        }
    }

    const { values, handleChange, errors, handleSubmit, initialValues } = useFormik({
        initialValues: {
            product_id: ``,
            image: ``
        },
        onSubmit: (values) => {

            values.image = fileState || '';

            const formData = new FormData();
            formData.append('file', file);

            setPending(true)

            axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(({ data }) => {


                values.image = `https://api.falconsavunma.com/images/${data.filename}`;

                axios.post("/api/addSinglePhotosOfProducts", values)
                    .then(({ data }) => {
                        if (data.err) {
                            Swal.fire(
                                'Error!',
                                'Something went wrong. Please Check the Console Screen!',
                                'error'
                            )
                            console.log(data.err)
                        }
                        else {
                            Swal.fire(
                                'Successful!',
                                'Data Added Successfully!',
                                'success'
                            ).then((result) => {
                                if (result.isConfirmed) {
                                    setAddShow(false);
                                }
                            })
                            setRender(!render)
                        }
                        setPending(false)
                    }
                    )
                    .catch(err => {
                        Swal.fire(
                            'Error!',
                            'Something went wrong. Please Check the Console Screen!',
                            'error'
                        )
                        console.log(err)
                        setPending(false)
                    });

            }).catch(error => {
                Swal.fire(
                    'Error!',
                    'Something went wrong. Please Check the Console Screen!',
                    'error'
                )
                console.log(error)
                setPending(false)
            });

        },
    });

    return (
        <>
            <Modal className='change_product' show={show}>
                {
                    pending ? <div className='penging'>
                        <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem", fontSize: "1.5rem" }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> : null
                }
                <Modal.Header closeButton onHide={setAddShow}>
                    <Modal.Title>Add New Photo in Product</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className='form_flex'>
                            <div className=''>
                                <label htmlFor="product_id">Choise Product</label>
                                <select className='choise' defaultValue={''} name="product_id" onChange={handleChange} required>
                                    <option value="" disabled>Choise Product</option>
                                    {products ? products.map((data, key) => <option key={key} value={`${data.id}`}>{data.product_name}</option>) : null}
                                </select>
                            </div>
                            <div>
                                <label htmlFor="image">Image</label>
                                <div className='type_file file1'>
                                    <input type="file" className="file" name='image' onChange={(e) => handleImageUpload(e.target.files[0])} />
                                    <div className='type_file_prev'>
                                        {fileState ? <img src={fileState} alt="" /> : <img src="https://cdn.iconscout.com/icon/free/png-256/free-photo-size-select-actual-1782180-1512958.png" alt="" />}
                                        <p>
                                            Select or drag a file to this area to replace the image
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className=''>
                                <div>
                                    <button type='submit'>Save</button>
                                </div>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default AddModal
