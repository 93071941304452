import React from 'react'

function PageRightArea() {
    return (
        <section className="page_right_area">
            <img src="https://storage.acerapps.io/app-1176/falcon/singleLogo.png" width="100%" alt="Falcon Savunma" />
        </section>
    )
}

export default PageRightArea
