import React, { useEffect, useState } from 'react'
import productDatas from '../../../productDatas';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import Table from '../../Components/Table';
import Swal from 'sweetalert2';
import ContentModal from '../../Components/ContentModal';
import ChangeModal from './Components/ChangeModal';
import AddModal from './Components/AddModal';

function Index() {

    const [allData, setAllData] = useState([]);
    const [pending, setPending] = useState(true);
    const [render, setRender] = useState(false);
    const [tableData, setTableData] = useState(allData);
    const [replaceInfos, setReplaceInfos] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState(false);

    const [changeShow, setChangeShow] = useState(false);
    const [addShow, setAddShow] = useState(false);

    let [dataId, setDataId] = useState(null)

    // Menü bilgilerini düzenlemek için function
    let replaceData = (id) => {

        setDataId(id)

        axios.get(`/getSingleProduct?id=${id}`)
            .then(({ data }) => {
                setReplaceInfos(data)
                setChangeShow(true)
            })
            .catch(err => console.log(err))
    }

    // Menü silmek için function
    let removeData = (id) => {


        setDataId(id)

        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger'
            },
            buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
            title: 'Are You Sure You Want to Delete??',
            text: "",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: "No, don't delete!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setPending(true);
                axios.post("/api/deleteProduct", { id: id })
                    .then(({ data }) => {
                        swalWithBootstrapButtons.fire({
                            title: 'Deleted !',
                            text: "",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'OK',
                        })
                        setRender(!render)
                    })
                    .catch(err => console.log(err));

            } else if (
                /* Read more about handling dismissals below */
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: 'Deletion Canceled !',
                    text: "",
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonText: 'OK',
                }
                )
            }
        })
    }

    let showContent = (content) => {
        setModalContent(content)
        setModalShow(true)
    }

    const columns = [
        {
            name: '#',
            selector: row => row.sira,
            width: "4rem"
        },
        {
            name: 'Product Name',
            selector: row => row.product_name,
            width: "15rem"
        },
        {
            name: 'Content',
            selector: row => row.content,
        },
        {
            name: 'Capacity',
            selector: row => row.capacity === "" ? "-" : row.capacity,
        },
        {
            name: 'Overall Size',
            selector: row => row.overall_size === "" ? "-" : row.overall_size,
        },
        {
            name: 'Power Supply',
            selector: row => row.power_supply === "" ? "-" : row.power_supply,
        },
        {
            name: 'Air Pressure',
            selector: row => row.air_pressure === "" ? "-" : row.air_pressure,
        },
        {
            name: 'Shot Diameter',
            selector: row => row.shot_diameter === "" ? "-" : row.shot_diameter,
        },
        {
            name: 'Production Capacity',
            selector: row => row.production_capacity === "" ? "-" : row.production_capacity,
        },
        {
            name: 'Actions',
            selector: row => row.actions,
            width: "10rem"
        }
    ];

    const renderDataTable = (datas) => {

        if (pending) {
            return (<div className='dataLoad'>
                <p className='load_text'>Products Loading</p>
                <div style={{ position: "relative" }}>
                    <div className='loader'>
                        <ClipLoader color='white' size={45} />
                    </div>
                </div>
            </div>)
        }
        return (
            <div>
                <Table columns={columns} datas={datas} noDataComponentText={"Product Not Found"} />
            </div>
        );
    };

    useEffect(() => {
        sessionStorage.setItem("page", "/products")
    }, []);

    useEffect(() => {
        // console.log(process.env.URL)
        // setAllData(productDatas);
        axios.get('/getProducts').then((response) => {
            let datas = response.data;
            setAllData(datas)

            let newTableData = [];
            datas.forEach((data, key) => {

                newTableData.push({
                    sira: `${Number(key) + 1}`, ...data, content: <>{<div className='show_content' onClick={() => { showContent([data.product_name, data.content]) }}>Show</div>}</>, actions: <div className='table_action'><button type="button" className="btn btn-primary" onClick={() => replaceData(data.id)}><i className="fa-solid fa-pencil"></i></button><button type="button" onClick={() => removeData(data.id)} className="btn btn-danger"><i className="fa-solid fa-trash"></i></button></div>
                })

            });

            setTableData(newTableData)
            setPending(false);

        }).catch((err) => console.log(err))
    }, [render]);


    const [file, setFile] = useState(null);

    const fileSub = (e) => {
        e.preventDefault();

        // FormData nesnesi oluştur
        
    };

    return (
        <>
            {/* <h2>File Upload With <code>"Node.js"</code></h2>
            <form onSubmit={fileSub}>
                <div>Select a file:
                    <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])} multiple="multiple" />
                </div>
                <button type='submit'>
                    Upload
                </button>
            </form> */}

            {!pending ? <button onClick={() => setAddShow(true)} className='btn btn-success mb-3'>Add Product</button> : null}
            <div className='product_table'>
                {renderDataTable(tableData)}
            </div>


            {changeShow ? <ChangeModal render={render} setRender={setRender} show={changeShow} setChangeShow={() => setChangeShow(false)} datas={replaceInfos} /> : null}
            {addShow ? <AddModal render={render} setRender={setRender} show={addShow} setAddShow={() => setAddShow(false)} /> : null}
            <ContentModal show={modalShow} onHide={() => setModalShow(false)} content={modalContent[1]} />
        </>
    )
}

export default Index
