import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('wrapper'));
// console.log(root)
root.render(
  <Router>
    {/* <Routes>
      <Route path='/' element={<App />} />
    </Routes> */}
    <App />
  </Router>
);

// Perform other setup tasks like measuring performance, logging, etc.
// reportWebVitals(console.log);
