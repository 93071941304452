import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import $ from 'jquery';

function Index() {

    useEffect(() => {
        sessionStorage.setItem("page", "/settings")
    }, []);

    const [pending, setPending] = useState(false);

    const [settings, setSettings] = useState({
        phone_number: '',
        mail: '',
        location: '',
        instagram: '',
        youtube: '',
        linkedin: ''
    });
    const [render, setRender] = useState(false);

    useEffect(() => {
        axios.get('/getSettings').then(({ data }) => {
            setSettings(data)
        })
    }, [render]);

    const handleChange = (name, value) => {
        setSettings(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setPending(true)
        axios.post('/api/setSettings', settings).then(({ data }) => {
            if (data.err) {
                Swal.fire(
                    'Error!',
                    'Something went wrong. Please Check the Console Screen!',
                    'error'
                )
                console.log(data.err)
            } else {
                Swal.fire(
                    'Successful!',
                    'Settings Updated Successfully!',
                    'success'
                )
                setRender(!render)
            }
            setPending(false)
        }).catch((err) => {
            Swal.fire(
                'Error!',
                'Something went wrong. Please Check the Console Screen!',
                'error'
            )
            console.log(err)
        })
    };



    return (
        <>
            <Tooltip anchorSelect=".phone_number-tooltip" place="left">
                To enter multiple values, you can put a vertical line (|) between them.
            </Tooltip>

            {
                pending ? <div className='penging'>
                    <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem", fontSize: "1.5rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> : null
            }
            {settings ? <form onSubmit={(e) => handleSubmit(e)} className='settings_area'>
                <div className='settings_flex'>
                    <div className='settings_input_flex'>
                        <div className=''>
                            <label htmlFor="phone_number">Phone <span className='phone_number-tooltip tooltip_icon'>?</span></label>
                            <input type="text" name='phone_number' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.phone_number} placeholder='Phone' />
                        </div>
                        <div className=''>
                            <label htmlFor="mail">Mail</label>
                            <input type="text" name='mail' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.mail} placeholder='Mail' />
                        </div>
                    </div>

                    <div className='location_input'>
                        <label htmlFor="location">Location</label>
                        <textarea placeholder='Location' name='location' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.location}></textarea>
                    </div>

                    <div className='settings_input_flex'>
                        <div className=''>
                            <label htmlFor="instagram">Instagram</label>
                            <input type="text" name='instagram' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.instagram} placeholder='Instagram' />
                        </div>
                        <div className=''>
                            <label htmlFor="youtube">Youtube</label>
                            <input type="text" name='youtube' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.x} placeholder='X' />
                        </div>
                        <div className=''>
                            <label htmlFor="linkedin">Linkedin</label>
                            <input type="text" name='linkedin' onChange={(e) => handleChange(e.target.name, e.target.value)} defaultValue={settings.linkedin} placeholder='Linkedin' />
                        </div>
                    </div>

                    <button>Save</button>
                </div>
            </form> : <div className='dataLoad'>
                <p className='load_text'>Settings Loading</p>
                <div style={{ position: "relative" }}>
                    <div className='loader'>
                        <ClipLoader color='white' size={45} />
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Index
