import * as Yup from 'yup';
import "yup-phone";

export const ContactValidation = Yup.object().shape({
    fullname: Yup.string()
        .min(3, 'Must be at least 3 characters!')
        .max(30, 'Must be at most 30 characters!')
        .required('This field is required!'),
    email: Yup.string()
        .email('Enter a valid email address!')
        .required('This field is required!'),
    phoneNumber: Yup.string()
        .required('This field is required!'),
    message: Yup.string()
        .min(3, 'Must be at least 3 characters!')
        .required('This field is required!')
});