import React, { useCallback, useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './Crop';

function CropImage({ image, setFileValid, file, setCropModal, croppedImage, setCroppedImage, handleImageUpload }) {
    const [cropperSize, setCropperSize] = useState(null);

    useEffect(() => {
        const { width, height } = window;
        console.log(width)
        setCropperSize({ width, height });
    }, []);

    let closeModal = () => setCropModal(null);


    function urlToBase64(url, callback) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
            );
            console.log(croppedImage)
            // console.log("donee", { croppedImage });

            urlToBase64(croppedImage, function (url) {
                handleImageUpload(file, 'crop', url)
            })

            setCroppedImage(croppedImage);
            setFileValid(true)
            setCropModal(null);

        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, image]);


    return (
        <>
            {image ? <><div onClick={closeModal} style={{ zIndex: "99999" }} className='_Modal_Overlay'></div>
                <div id='cropImageModal' style={{ zIndex: "999999" }} className={`_Modal ${image ? 'open' : null}`}>

                    <div className='_Modal_Content Crop_Modal'>
                        <div className='_Modal_Head'>
                            <h3>
                                Resize Image
                            </h3>
                            <i onClick={closeModal} className="fa-sharp fa-solid fa-xmark _Close_Modal"></i>
                        </div>
                        <div className='_Modal_Body CropModal'>
                            <div className='CropImageArea'>


                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    zoomSpeed={.15}
                                    maxZoom={2}
                                    objectFit='cover'
                                    zoomWithScroll={true}
                                    // aspect={4 / 3}
                                    cropSize={{ width: 1920, height: 1080 }}
                                    showGrid={false}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />


                            </div>

                        </div>
                        <div className='_Modal_Footer'>
                            <button
                                className='btn btn-success cropBtn'
                                onClick={showCroppedImage} >
                                Crop
                            </button>
                        </div>
                    </div>
                </div></> : <div className='loadModule'>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}


        </>
    )
}

export default CropImage
