import React, { useEffect, useState } from 'react'
import '../Css/MainHeader.css';
import { NavLink } from 'react-router-dom';
import $ from "jquery";
import productDatas from '../productDatas';
import axios from 'axios';

export function Header() {

    let menuControl = (elem) => {
        $("header.main-header .header-nav").toggleClass("active");
        $(".header-flex").toggleClass("active");
        setTimeout(() => {
            if ($(".header-flex").hasClass("active")) {
                $(".header-flex input").prop("checked", true);
            } else {
                $(".header-flex input").prop("checked", false);
            }
        },
            1);
    }

    const [openDrop, setOpenDrop] = useState(false);
    const [allData, setAllData] = useState([]);

    useEffect(() => {


        axios.get('/getProducts').then(({ data }) => setAllData(data)).catch((err) => console.log(err))

    }, []);

    return (
        <>
            <header className="main-header">
                <div className="container-fluid">
                    <div className="header-flex">
                        <div className="header-logo">
                            <NavLink to="/">
                                <img src="https://storage.acerapps.io/app-1517/assets/img/logo.png" alt="Falcon Savunma" />
                            </NavLink>
                        </div>
                        <div className="header-nav">
                            {/* <div className="header-burger inner-check">
                                <div className="menuToggle">
                                    <input type="checkbox" />
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div> */}
                            <ul className="navigation-list">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                <li className={`drop_link_main ${openDrop ? "active" : ""}`} open={openDrop ? "open" : ""}>
                                    <NavLink onClick={() => setOpenDrop(!openDrop)}>Products</NavLink>
                                    <ul className="drop_link">
                                        {allData ? allData.map((data, key) => <li key={key}>
                                            <NavLink to={`/product/${data.link}`}>
                                                {data.product_name}
                                            </NavLink>
                                        </li>) : null}
                                    </ul>
                                </li>
                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                            </ul>
                        </div>
                        <div className="header-burger">
                            <div className="menuToggle" onClick={(e) => menuControl(e.target)}>
                                <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="overlay-header" onClick={(e) => menuControl(e.target)}></div>
                    </div>
                </div>
            </header>
        </>
    )
}

export function MainPageHeader() {

    let menuControl = (elem) => {
        $("div.main-header .header-nav").toggleClass("active");
        $(".header-flex").toggleClass("active");
        setTimeout(() => {
            if ($(".header-flex").hasClass("active")) {
                $(".header-flex input").prop("checked", true);
            } else {
                $(".header-flex input").prop("checked", false);
            }
        },
            1);
    }

    const [come, setCome] = useState(false);
    const [openDrop, setOpenDrop] = useState(false);
    const [allData, setAllData] = useState([]);

    let dropLink = () => {
        $(".drop_link_main").toggleClass("active")
        setOpenDrop(true)
    }

    useEffect(() => {

        axios.get('/getProducts').then(({ data }) => setAllData(data)).catch((err) => console.log(err))

        setTimeout(() => {
            setCome(true)
        }, 4500);


    }, []);


    return (
        <>
            <div className={`main-header main-page`} op={come ? "op" : ""}>
                <div className="container-fluid">
                    <div className="header-flex">
                        <div className="header-logo">
                            <NavLink to="/">
                                <img src="https://storage.acerapps.io/app-1517/assets/img/logo.png" alt="Falcon Savunma" />
                            </NavLink>
                        </div>
                        <div className="header-nav">
                            {/* <div className="header-burger inner-check">
                                <div className="menuToggle">
                                    <input type="checkbox" />
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </div> */}
                            <ul className="navigation-list">
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/about-us">About Us</NavLink></li>
                                <li className="drop_link_main" open={openDrop ? "open" : "not"}>
                                    <NavLink onClick={() => dropLink()}>Products</NavLink>
                                    <ul className="drop_link">
                                        {allData ? allData.map((data, key) => <li key={key}>
                                            <NavLink to={`/product/${data.link}`}>
                                                {data.product_name}
                                            </NavLink>
                                        </li>) : null}
                                    </ul>
                                </li>
                                <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                            </ul>
                        </div>
                        <div className="header-burger">
                            <div className="menuToggle" onClick={(e) => menuControl(e.target)}>
                                <input type="checkbox" />
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
