import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function SocialMedias() {

    const [settings, setSettings] = useState({});

    useEffect(() => {
        axios.get('/getSettings').then(({ data }) => {
            setSettings(data)
        })
    }, []);

    return (
        <div className='social_area'>
            {settings.instagram ? <Link to={`${settings.instagram}`} target='_blank'>
                <i className="fa-brands fa-instagram"></i>
            </Link> : null}

            {settings.youtube ? <Link to={`${settings.youtube}`} target='_blank'>
                <i class="fa-brands fa-youtube"></i>
            </Link> : null}

            {settings.linkedin ? <Link to={`${settings.linkedin}`} target='_blank'>
                <i className="fa-brands fa-linkedin-in"></i>
            </Link> : null}

            {settings.mail ? <Link to={`mailto:${settings.mail}`} target='_blank'>
                <i className="fa-solid fa-envelope"></i>
            </Link> : null}
        </div>
    )
}

export default SocialMedias
