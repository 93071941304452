import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function Redirect({ url }) {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(url, { replace: true });
    }, [navigate, url]);

    return null;
}
