import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import Table from '../../Components/Table';
import Swal from 'sweetalert2';
import ContentModal from './Components/ContentModal';

function Index() {

    useEffect(() => {
        sessionStorage.setItem("page", "/forms")
    }, []);

    const [allData, setAllData] = useState([]);
    const [pending, setPending] = useState(true);
    const [render, setRender] = useState(false);
    const [tableData, setTableData] = useState(allData);
    const [replaceInfos, setReplaceInfos] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState(false);

    const [changeShow, setChangeShow] = useState(false);
    const [addShow, setAddShow] = useState(false);

    const handleClose = () => setChangeShow(false);
    const handleShow = () => setChangeShow(true);


    let showContent = (content) => {
        setModalContent(content)
        setModalShow(true)
    }

    const columns = [
        {
            name: '#',
            selector: row => row.sira,
            width: "4rem"
        },
        {
            name: 'Fullname',
            selector: row => row.fullname,
            width: "15rem"
        },
        {
            name: 'E-mail',
            selector: row => row.email,
        },
        {
            name: 'Phone',
            selector: row => row.phoneNumber,
        },
        {
            name: 'Message',
            selector: row => row.message,
        }
    ];

    const renderDataTable = (datas) => {

        if (pending) {
            return (<div className='dataLoad'>
                <p className='load_text'>Mails Loading</p>
                <div style={{ position: "relative" }}>
                    <div className='loader'>
                        <ClipLoader color='white' size={45} />
                    </div>
                </div>
            </div>)
        }
        return (
            <div>
                <Table columns={columns} datas={datas} noDataComponentText={"Product Not Found"} />
            </div>
        );
    };

    useEffect(() => {
        sessionStorage.setItem("page", "/forms")
    }, []);

    useEffect(() => {
        // setAllData(productDatas);
        axios.get('/api/getFormData').then((response) => {
            let datas = response.data;
            setAllData(datas)
            let newTableData = [];
            datas.forEach((data, key) => {

                newTableData.push({
                    sira: `${Number(key) + 1}`, ...data, message: <>{<div className='show_content' onClick={() => { showContent({ name: data.fullname, email: data.email, content: data.message }) }}>Show</div>}</>
                })

            });

            setTableData(newTableData)
            setPending(false);

        }).catch((err) => console.log(err))
    }, [render]);

    return (
        <>
            <div>
                {renderDataTable(tableData)}
            </div>
            <ContentModal show={modalShow} onHide={() => setModalShow(false)} content={modalContent} />
        </>
    )
}

export default Index
