import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import $ from 'jquery';

function Index() {
    const [render, setRender] = useState(false);
    const [pending, setPending] = useState(false);






    function convertFileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                resolve(event.target.result);
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    }

    const handleImageUpload = async (file, index) => {
        try {
            const fileType = getFileTypeFromExtension(file.name.split('.').pop());
            if (fileType === "image") {
                let url = await convertFileToBase64(file)

                let updatedImages = [...newData.images];
                updatedImages[index] = url;

                setNewData({ ...newData, images: updatedImages });
            }
            else {
                Swal.fire(
                    'Unsuccessful',
                    `Invalid file selected !`,
                    'error'
                )
            }


        } catch (error) {
            console.log(error)
        }
    };


    function getFileTypeFromExtension(extension) {
        switch (extension.toLowerCase()) {
            case 'jpg':
                return 'image';
            case 'jpeg':
                return 'image';
            case 'png':
                return 'image';
            case 'webp':
                return 'image';
            case 'pdf':
                return 'pdf';
            case 'doc':
                return 'doc';
            case 'docx':
                return 'docx';
            default:
                return 'undefined';
        }
    }


    const [content, setContent] = useState([]);



    const [newData, setNewData] = useState(content)


    useEffect(() => {
        sessionStorage.setItem("page", "/text")

        axios.get('/getAbout')
            .then(({ data }) => {
                setContent(data)
                setNewData(data)
            })

    }, [render]);


    let saveInformation = () => {

        newData.data = $(".ck.ck-content") ? $(".ck.ck-content").html() : ""
        setPending(true)
        axios.post('/api/setAbout', {
            data: newData.data,
            image1: newData.images[0],
            image2: newData.images[1],
            image3: newData.images[2],
            id: newData.id
        })
            .then(({ data }) => {
                if (data.err) {
                    Swal.fire(
                        'Error!',
                        'Something went wrong. Please Check the Console Screen!',
                        'error'
                    )
                    console.log(data.err)
                }
                else {
                    Swal.fire(
                        'Successful!',
                        'About Us Page Updated!',
                        'success'
                    )
                    setRender(!render)
                }
                setPending(false)
            }
            )
            .catch(err => {
                Swal.fire(
                    'Error!',
                    'Something went wrong. Please Check the Console Screen!',
                    'error'
                )
                console.log(err)
            });
    }

    let removeImage = (index) => {
        let updatedImages = [...newData.images];
        updatedImages[index] = "";

        setNewData({ ...newData, images: updatedImages });
    }


    return (
        <>
            {
                pending ? <div className='penging'>
                    <div className="spinner-border text-light" style={{ width: "4rem", height: "4rem", fontSize: "1.5rem" }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div> : null
            }
            {newData && newData.images ? <>
                <div className='about_area'>
                    <div className='container'>
                        <div className="page_content">
                            <CKEditor
                                name='about'
                                data={content.data}
                                editor={ClassicEditor} />


                            <div className="row g-3 mt-2">
                                {newData.images.map((data, key) => {
                                    if (data || data !== "") {
                                        return <div className={key === 0 ? "col-12" : "col-6"} key={key}>
                                            <div className='img current'>
                                                <img src={`${data}`} alt="" />
                                                <div className='current_file'>
                                                    <div>
                                                        <label className='remove_image' onClick={() => removeImage(key)}>Remove Image</label>
                                                    </div>
                                                    <div>
                                                        <input type="file" name={`file${key}`} id={`file${key}`} onChange={(e) => handleImageUpload(e.target.files[0], key)} className="inputfile" />
                                                        <label htmlFor={`file${key}`}>Change Image</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    else {
                                        return <div className={key === 0 ? "col-12" : "col-6"} key={key}>
                                            <div className='img'>
                                                <input type="file" name={`file${key}`} id={`file${key}`} onChange={(e) => handleImageUpload(e.target.files[0], key)} className="inputfile" />
                                                <label htmlFor={`file${key}`}>Choose a Image</label>
                                            </div>
                                        </div>;
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </> : <div style={{ position: "relative" }}>
                <div className='dataLoad'>
                    <p className='load_text'>About Us Page Loading</p>
                    <div style={{ position: "relative" }}>
                        <div className='loader'>
                            <ClipLoader color='white' size={45} />
                        </div>
                    </div>
                </div>
            </div>}

            {newData && newData.images ? <>
                <button className='btn_style' onClick={saveInformation} >Save</button>
            </> : null}

        </>
    )
}

export default Index
