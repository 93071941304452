import React from 'react';


function BannerProduct({ data }) {

    // const { product, setProduct } = useContext(ProductContext);

    return (
        <li className="js-canvas__target"
            data-canvas-url={`/product/${data.link}`}
            data-canvas-thumb={data.image}
            data-canvas-zoom={data.image}>
            {data.product_name}
        </li>
    )
}

export default BannerProduct
